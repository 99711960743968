#printer  {
    display: none;
}
@media print {
	#printer  {
        display: block;
        font-family: 'Nunito', sans-serif;
        z-index: 99999999;
	}
}
.print-divider{
    width: 100%;
    border:1px dotted black
}
.container-print-paid{
    display: flex;
    background-color: black;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-bottom: -20px;
}
.print-paid{
    width: 150px;
    border:1px dotted black;
}
.print-paid-xpress{
    width: 350px;
    border:1px dotted black;
    margin-top: 10px;
}